import React from "react"
import { Projetos2023Data } from "@/data"

const ProjetosFinalistas2023 = ({}) => {
  return (
   <section className="cards row" style={{ background: 'transparent' }}>

    {Projetos2023Data.map(({image,title,lider,description,url,votar}) => (

      <div className="col-lg-4 col-md-6">
      <div className="card-projeto">
        <img src={image} alt="" />
        <h2>{title}</h2>
        <span>Líder do grupo:</span>
        <span>{lider}</span>
        <p>{description}</p>
        {/* <a href={url} target="_blank">conheça o projeto</a> */}
        <a href={votar} target="_blank">Votar no projeto</a>
      </div>
      </div>

    ))}

   </section>
  );
};

export default ProjetosFinalistas2023;
